import TheStars from "../components/TheStars";
import LeftHero from "../components/LeftHero";
import RightHero from "../components/RightHero";
import BasicModal from "../components/BasicModal";

import logo from "../assets/meCvLogo.png";
import IgIcon from "../assets/ig icon.png";
import TwtIcon from "../assets/twt icon.png";


import "./css/responsiveComingSoon.css";

const ComingSoon = () => {

  return (
    <>
      <TheStars />
      <div className="comingSoonScaffolding">
        <header className="landingHeader">
          <img className="meCvLogo" src={logo} alt={"Me CV logo"} />
          <section className={`socialIcons`}>
            <a
              href="https://www.instagram.com/mecvcom/"
              target="_blank"
              rel="noopener noreferrer"
              className="iconContainer"
            >
              <img src={IgIcon} alt="Follow __me" id="icon" className="ig" />
            </a>
            <a
              href="https://twitter.com/mecvcom"
              target="_blank"
              rel="noopener noreferrer"
              className="iconContainer"
            >
              <img src={TwtIcon} alt="Follow __me" id="icon" className="twt" />
            </a>
          </section>
        </header>
        <section id={"heroSection"}>
          <LeftHero />
          <RightHero />
        </section>
      </div>
      <BasicModal />
    </>
  );
};

export default ComingSoon;
