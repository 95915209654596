import { useState, useEffect } from "react";
import ComingSoon from "./pages/ComingSoon";

import { useSelector } from "react-redux";
import { RootState } from "./store/types";

import "./App.css";
import LoadingProgress from "./components/LoadingProgress";

function App() {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const isLoading = useSelector((state: RootState) => state.appState.isLoading)

  const loadingThing = () => {
    setTimeout(() => {
      setIsLoaded((prev) => !prev);
    }, 4000);
  };

  useEffect(() => {
    loadingThing();
  }, []);

  return (
    <div className="App">
      <ComingSoon />
      {(!isLoaded || isLoading) && <LoadingProgress /> }
    </div>
  );
}

export default App;
