import { useState } from "react";
import { useDispatch } from "react-redux";
import { appStateSliceActions } from "../store/applicationState";
import { Button, TextField, Box } from "@mui/material";
import "./css/responsiveForm.css";
import { validation } from "./utils/validation";

export interface ForForm {
  error: {
    errorName: string;
    errorText: string;
  };

  ErrorResponse: {
    status: number;
    message: string;
  };
}

const Form = () => {
  const dispatch = useDispatch();

  const handleModalOpen = () => {
    dispatch(appStateSliceActions.openSignupModal());
  };

  const handleErrorModal = (errorMessage: string) => {
    dispatch(appStateSliceActions.setErrorMessage({ message: errorMessage }));
  };

  const handleStandOutClick = () => {
    dispatch(appStateSliceActions.standOutClick());
  };

  const handleSetIsLoading = () => {
    dispatch(appStateSliceActions.rdxSetIsLoading());
  };

  const [error, setError] = useState<ForForm["error"]>({
    errorName: "",
    errorText: "",
  });

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [telephone, setTelephone] = useState<string>("");

  //image upload states
  const [image, setImage] = useState<string | Blob>();
  const [uploadingImg, setUploadingImg] = useState<boolean>(false);
  const [imagePreview, setImagePreview] = useState<string>("");

  const placeholderPic = `${process.env.REACT_APP_PLACEHOLDER_IMAGE}`;

  function validateImg(event: React.ChangeEvent<HTMLInputElement>) {
    if (!event!.target.files) {
      return;
    } else {
      const file = event!.target.files[0];
      if (file.size >= 5048576) {
        return alert("Max file size is 5mb");
      } else {
        setImage(file);
        setImagePreview(URL.createObjectURL(file));
      }
    }
  }

  async function uploadImage() {
    const data = new FormData();
    data.append("file", image!);
    data.append("upload_preset", "chatSignin");
    // console.log(data)
    try {
      setUploadingImg(true);
      let res = await fetch(`${process.env.REACT_APP_CLOUDINARY_URL}`, {
        method: "POST",
        body: data,
      });
      const urlData = await res.json();
      setUploadingImg(false);
      return urlData.url;
    } catch (error) {
      setUploadingImg(false);
      console.log(error);
    }
  }

  const handleSubmit = async (event: {
    preventDefault: () => void;
    currentTarget: HTMLFormElement | undefined;
  }) => {
    handleSetIsLoading();
    event.preventDefault();
    if (!image) return alert("Please upload your profile picture");
    if (
      !email ||
      !password ||
      !firstname ||
      !lastname ||
      !telephone ||
      !username
    )
      return alert("all fields of form need to be filled!");
    let url: string = await uploadImage();
    // console.log(e.currentTarget)

    if (uploadingImg === false) {
      let requestData = JSON.stringify({
        email: email,
        password: password,
        firstname: firstname,
        lastname: lastname,
        username: username,
        telephone: telephone,
        picture: url,
      });
      try {
        let signupResult = await fetch(
          `${process.env.REACT_APP_MECV_API_URL}/signup`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            method: "POST",
            body: requestData,
          }
        );

        if (signupResult.status === 201) {
          handleSetIsLoading();
          handleModalOpen();
          setEmail("");
          setPassword("");
          setFirstname("");
          setLastname("");
          setUsername("");
          setTelephone("");
          return handleStandOutClick();
        } else if (!signupResult.ok) {
          const errorResponse: ForForm["ErrorResponse"] =
            await signupResult.json();

          throw new Error(errorResponse.message);
        } else return;
      } catch (error) {
        handleSetIsLoading();
        if (error instanceof Error) {
          if (error.message.includes("already exists")) {
            handleErrorModal("duplicate");
          }
        } else {
          handleErrorModal("unknown");
        }
      }
    }
  };

  // useEffect(() => {},[email])

  return (
    <>
      <Box component="form" onSubmit={handleSubmit} className="formContainer">
        <div className={`formRow formLabel`}>
          <p className="formPrompt">
            <span id={`engaging`}>Great choice!</span> Just fill out the form
            below and we'll let you know when it's time to go!
          </p>
        </div>

        <div className={`formRow fnLnUname`}>
          <TextField
            onChange={(e) => setFirstname(e.target.value)}
            value={firstname}
            sx={{
              backgroundColor: "white",
              borderRadius: "5px",
            }}
            size="small"
            className={`rowOneInput firstname`}
            required
            label="First Name"
          />
          <TextField
            onChange={(e) => setLastname(e.target.value)}
            value={lastname}
            sx={{
              backgroundColor: "white",
              borderRadius: "5px",
            }}
            size="small"
            className={`rowOneInput lastname`}
            required
            label="Last Name"
          />
          <TextField
            error={error && error.errorName === "username" ? true : false}
            helperText={
              error && error.errorName === "username" ? error.errorText : null
            }
            onChange={(e) =>
              validation({ username: e.target.value }, setUsername, setError)
            }
            value={username}
            sx={{
              backgroundColor: "white",
              borderRadius: "5px",
            }}
            size="small"
            className={`rowOneInput username`}
            autoComplete="username"
            label="Username"
            variant="outlined"
          />
        </div>
        <div className={`formRow telEmail`}>
          <TextField
            onChange={(e) => setTelephone(e.target.value)}
            value={telephone}
            sx={{
              backgroundColor: "white",
              borderRadius: "5px",
            }}
            size="small"
            className={`rowTwoInput Telephone`}
            required
            autoComplete="tel"
            label="Phone"
          />
          <TextField
            error={error && error.errorName === "email" ? true : false}
            helperText={
              error && error.errorName === "email" ? error.errorText : null
            }
            onChange={(e) =>
              validation({ email: e.target.value }, setEmail, setError)
            }
            value={email}
            sx={{
              backgroundColor: "white",
              borderRadius: "5px",
            }}
            size="small"
            className={`rowTwoInput E-mail`}
            required
            autoComplete="email"
            label="E-mail"
            variant="outlined"
          />
        </div>
        <div className={`formRow passPic`}>
          <TextField
            error={error && error.errorName === "password" ? true : false}
            helperText={
              error && error.errorName === "password" ? error.errorText : null
            }
            onChange={(e) =>
              validation({ password: e.target.value }, setPassword, setError)
            }
            value={password}
            sx={{
              backgroundColor: "white",
              borderRadius: "5px",
            }}
            size="small"
            className={`rowThreeInput password`}
            type="password"
            label="Future Password"
            variant="outlined"
          />
          <div className="signup-profile-pic__container">
            <img
              src={imagePreview || placeholderPic}
              alt="avatar placeholder"
              className="signup-profile-pic"
            />
            <label htmlFor="image-upload" className="image-upload-label">
              <i className="fas fa-plus-circle add-picture-icon"></i>
            </label>
            <input
              type="file"
              id="image-upload"
              hidden
              accept="image/png, image/jpeg"
              onChange={validateImg}
            />
          </div>
          <span id="imageUploadPrompt">
            <p className="iupText">😁🤳Let's see those pearly whites!</p>
            <p className="iupText instruct">{`(click the ➕ icon)`}</p>
          </span>
        </div>
        <div className={`formRow button`}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              backgroundColor: "var(--velvet-blue)",
              mt: 3,
              mb: 2,
              fontWeight: 700,
              fontFamily: `"Inter", sans-serif`,
            }}
          >
            let's go! 🚀
          </Button>
        </div>
      </Box>
    </>
  );
};

export default Form;
