import { SetStateCallback } from "../types/componentTypes";
import { ForForm } from "../Form";

export const validation: <T, U extends ForForm["error"]>(
  arg1: { [key: string]: string },
  setStateCallback: SetStateCallback<T>,
  setStateCallbackB: SetStateCallback<U>
) => void | string = <T, U>(
  testValue: { [key: string]: string },
  SetStateCallback: SetStateCallback<T>,
  SetStateCallbackB: SetStateCallback<U>
) => {
  const key = Object.keys(testValue)[0];
  const val = testValue[key];

  const errorSetter = (abebo: string, errName: string, errText: string) => {
    SetStateCallback((prev) => abebo as T);
    SetStateCallbackB(
      (prev: U) =>
        ({
          ...prev,
          errorName: errName,
          errorText: errText,
        } as U)
    );
  };

  switch (key) {
    case "email":
      const emailRegex = /\S+@\S+\.\S+/;
      const isValid = emailRegex.test(val);

      if (isValid) {
        errorSetter(val, "", "");
      } else {
        errorSetter(val, "email", "email needs correction 🤔");
      }
      break;

    case "password":
      const capsRegex = /[A-Z]/;
      const specRegex = /[!@#$%^&*(),.?":{}|<>]/;
      const numRegex = /[0-9]/;

      if (val.length < 8) {
        errorSetter(val, "password", "Password too short 🥲");
      } else if (!capsRegex.test(val)) {
        errorSetter(val, "password", "1 Uppercase letter's needed 😥");
      } else if (!specRegex.test(val)) {
        errorSetter(val, "password", "add 1 Special Character 💫");
      } else if (!numRegex.test(val)) {
        errorSetter(val, "password", "1 number needed 💯");
      } else {
        errorSetter(val, "", "");
      }
      break;

    case "username":
      if (val.length >= 12) {
        errorSetter(val, "username", "username too long 🚂");
      } else {
        errorSetter(val, "", "");
      }
      break;

    default:
      return;
  }
};
