import * as React from "react";

import Modal from "@mui/material/Modal";
import { Stack, Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { appStateSliceActions } from "../store/applicationState";
import { RootState } from "../store/types";

import "./css/BasicModal.css";

const BasicModal: React.FC = () => {
  const dispatch = useDispatch();

  const isOpenModal = useSelector(
    (state: RootState) => state.appState.signupModalOpen
  );
  const errorMessage = useSelector(
    (state: RootState) => state.appState.errorMessage
  );
  const handleClose = () => {
    dispatch(appStateSliceActions.openSignupModal());
  };

  return (
    <Modal
      open={isOpenModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div>
        <Stack
          className="backDrop"
          sx={{
            backgroundColor: "var(--off-white)",
            width: "350px",
            height: `290px`,
            ml: "auto",
            mr: "auto",
            mt: "15%",
            border: "1px solid var(--velvet-blue)",
          }}
        >
          <Stack
            sx={{
              backgroundColor: "var(--off-white)",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              height: "22%",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
            }}
          >
            <div className="message-icon"></div>
            <Typography
              variant="body1"
              className="writing"
              sx={{
                ml: 1.5,
                mt: "auto",
                mb: "auto",
                color: "var(--deep-blue)",
                fontWeight: 700,
                fontFamily: `"Playfair Display", serif`,
              }}
            >
              {errorMessage
                ? "🫠 We might have a problem..."
                : "Glad you could join us!"}
            </Typography>
          </Stack>
          <Stack
            className="textBody"
            sx={{
              display: "flex",
              flexDirection: "column",

              height: "82%",
              backgroundColor: "white",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                ml: 1.5,
                mt: 2,
                mb: -0.25,
                mr: 1.5,
                fontWeight: 700,
                fontFamily: `"Inter", sans-serif`,
                color: "var(--deep-blue)",
              }}
            >
              {errorMessage
                ? "this is flabbergasting"
                : "Thanks for registering!"}
            </Typography>
            <Typography
              sx={{
                ml: 1.5,
                mt: 0.25,
                mr: 1.5,
                fontFamily: `"Inter", sans-serif`,
                color: "var(--deep-blue)",
              }}
            >
              {errorMessage === "duplicate"
                ? "Some of your credentials match those in our database already. If you registered before, you don't have to again."
                : "In a few, there will be a confirmation link in the email you provided. Click it and you're set! When we're up, you'll be among the very first to know!"}
            </Typography>
            <Typography sx={{ ml: 1.5, mt: 0.25, mr: 1.5 }}></Typography>
            <Typography
              sx={{
                ml: 1.5,
                mt: 0.25,
                mr: 1.5,
                fontFamily: `"Inter", sans-serif`,
                color: "var(--deep-blue)",
              }}
            >
              Finally,{" "}
              <Typography
                sx={{
                  fontWeight: 600,
                  fontFamily: `"Inter", sans-serif`,
                  color: "var(--velvet-blue)",
                }}
              >
                {errorMessage === "duplicate"
                ?"Check and try again in a few minutes":"tell a friend to tell a friend!😉"}
              </Typography>
            </Typography>
          </Stack>
        </Stack>
      </div>
    </Modal>
  );
};

export default BasicModal;
