import logo from "../assets/meCvLogo.png";
import "./css/LoadingProgress.css";

const LoadingProgress = () => {
  return (
    <div className="loadingProgress">
      <img src={logo} alt="me-cv logo for loading" className="logoToSpin" />
    </div>
  );
};

export default LoadingProgress;
