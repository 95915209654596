import { createSlice } from "@reduxjs/toolkit";

const appStateSlice = createSlice({
    name: 'appState',
    initialState: {
        standOutClicked: false,
        signupModalOpen: false,
        errorMessage: "",
        isLoading: false,
    },
    reducers: {
        standOutClick: (state) => {
            state.standOutClicked = !state.standOutClicked
        },
        openSignupModal: (state) => {
            state.signupModalOpen = !state.signupModalOpen
        },
        setErrorMessage: (state, action) => {
            state.signupModalOpen = !state.signupModalOpen
            state.errorMessage = action.payload.message
        },
        rdxSetIsLoading: (state) => {
            state.isLoading = !state.isLoading
        }
    }
})

export const appStateSliceActions = appStateSlice.actions

export default appStateSlice