import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appStateSliceActions } from "../store/applicationState";
import { RootState } from "../store/types";
import "./css/responsiveLeftHero.css";

const LeftHero = () => {
  const standOut: boolean = useSelector(
    (state: RootState) => state.appState.standOutClicked
  );
  const dispatch = useDispatch();

  const handleStandOutClick = () => {
    dispatch(appStateSliceActions.standOutClick());
  };

  useEffect(() => {}, [standOut]);

  return (
    <>
      <div className={`leftHero ${standOut ? "lesserWidth" : "normalWidth"}`}>
        <article className={`theMessage ${standOut ? "zoomOut" : "zoomIn"}`}>
          <h2 className="missionStatement">
            Ready for an <span id="engaging">upgrade?</span> It's time to try{" "}
            <span id="engaging">something new!</span> Say goodbye to the clunky
            traditional CV model and{" "}
            <span id="engaging">
              hello to a better way to showcase your skills
            </span>
            .
          </h2>
          <button onClick={handleStandOutClick} className="standOut">
            <div>
              {standOut ? (
                <>
                  <span id="stand">Fill</span> Out
                </>
              ) : (
                <>
                  <span id="stand">Stand</span> Out
                </>
              )}
            </div>
          </button>
        </article>
        
      </div>
    </>
  );
};

export default LeftHero;
