const RadialSvg = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 800 800"
      >
        <defs>
          <radialGradient id="cccircular-grad" r="50%" cx="50%" cy="50%">
            <stop offset="0%" stopColor="#00577e" stopOpacity="0.5"></stop>
            <stop offset="50%" stopColor="#4283ad" stopOpacity="0"></stop>
            <stop offset="100%" stopColor="#76b2df" stopOpacity="0.5"></stop>
          </radialGradient>
        </defs>
        <g fill="url(#cccircular-grad)">
          <circle r="413" cx="400" cy="400" opacity="0.05"></circle>
          <circle r="383.5" cx="400" cy="400" opacity="0.12"></circle>
          <circle r="354" cx="400" cy="400" opacity="0.20"></circle>
          <circle r="324.5" cx="400" cy="400" opacity="0.27"></circle>
          <circle r="295" cx="400" cy="400" opacity="0.34"></circle>
          <circle r="265.5" cx="400" cy="400" opacity="0.42"></circle>
          <circle r="236" cx="400" cy="400" opacity="0.49"></circle>
          <circle r="206.5" cx="400" cy="400" opacity="0.56"></circle>
          <circle r="177" cx="400" cy="400" opacity="0.63"></circle>
          <circle r="147.5" cx="400" cy="400" opacity="0.71"></circle>
          <circle r="118" cx="400" cy="400" opacity="0.78"></circle>
          <circle r="88.5" cx="400" cy="400" opacity="0.85"></circle>
          <circle r="59" cx="400" cy="400" opacity="0.93"></circle>
        </g>
      </svg>
    </>
  );
};

export default RadialSvg;
