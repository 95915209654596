import { useEffect } from "react"
import { useSelector }  from "react-redux"
import { RootState } from "../store/types"
import "./css/ScatteredCircles.css"

const ScatteredCircles = () => {
  const isClicked = useSelector((state: RootState) => state.appState.standOutClicked);

  useEffect(()=>{},[isClicked])
  
  return (
    <>
      <div className="sCircles">
        <svg
          className={`circlesThemselves ${isClicked ? "appear" : "dontShow"}`}
          id="visual"
          viewBox="0 0 900 600"
          width="900"
          height="600"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
        >
          <g fill="none" stroke="#4283ad" strokeWidth="2">
            <circle r="145" cx="283" cy="503"></circle>
            <circle r="63" cx="824" cy="242"></circle>
            <circle r="112" cx="52" cy="164"></circle>
          </g>
        </svg>
      </div>
    </>
  );
}

export default ScatteredCircles