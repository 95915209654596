import ScatteredCircles from "../components/ScatteredCircles";

import "./css/Stars.css";

const TheStars = () => {
  return (
    <div className="theStars">
      <ScatteredCircles />

      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
    </div>
  );
};

export default TheStars;
