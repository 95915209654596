import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/types";
import Form from "./Form";
import RadialSvg from "./RadialSvg";

import redCross from "../assets/redCross.png";
import radiation from "../assets/radiation.png";
import stressCv from "../assets/stressCv.png";
import laptopPng from "../assets/laptop_png.png";

import "./css/responsiveRightHero.css";
import "./css/responsiveRadialSvg.css";

const RightHero = () => {
  const isClicked = useSelector(
    (state: RootState) => state.appState.standOutClicked
  );

  useEffect(() => {}, [isClicked]);

  return (
    <>
      <div id="rightHeroContainer">
        <div className={`theForm ${isClicked ? "appear" : "dontShow"}`}>
          <Form />
        </div>

        <div className="laptopImage">
          <div className={`svgContainer ${isClicked ? "appear" : "dontShow"}`}>
            <RadialSvg />
          </div>

          <img
            src={radiation}
            alt="anger radiating"
            className={`radiation ${isClicked ? "vanish" : "vanish-reverse"}`}
          />
          <img
            src={stressCv}
            alt="the stress of cv editign"
            className={`stressCv ${isClicked ? "vanish" : "vanish-reverse"}`}
          />
          <img
            src={laptopPng}
            alt="laptop no screen"
            className={`actualLaptopImage ${isClicked ? "zoomed" : "unzoom"}`}
          />
          <img
            src={redCross}
            alt="cross over the stress and em"
            className={`redCross ${isClicked ? "vanish appear" : ""}`}
          />
        </div>
      </div>
    </>
  );
};

export default RightHero;
